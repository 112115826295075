import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSelector, useDispatch } from 'react-redux';

import InplaceEditPanel from "./InplaceEditPanel";
import CountAndDateSection from "./CountAndDateSection";
import { getRandomArbitrary } from "../../../common/utils";
import { getHandedoutCount, getMaxAllowedHandedoutCount } from "./HandoutsReturnsUtils";

import {
    selectInPlaceEditorShowing
} from '../../store/inplaceEditorSlice';

import {
    convertDateForServer,
    addNewHandout,
    changeHandout
} from '../../store/reservationSlice';

const CountAndHandedOutSection = (props) => {
    const { isSubarticle, handouts } = props;

    useEffect(() => {
        // setting the key for new handouts to a random value, otherwise the entry will not be refreshed
        const randomKey = getRandomArbitrary(50000, 60000);
        setKeyForNewHandoutEntry(randomKey);
    }, [handouts]);

    const dispatch = useDispatch();
    const isInplaceEditAlreadyShowing = useSelector(selectInPlaceEditorShowing);

    // count is the rented count
    const [currentCount, setCurrentCount] = useState(props.count);
    const [isEditingCount, setIsEditingCount] = useState(false);
    const [isCountValid, setIsCountValid] = useState(true);

    const [keyForNewHandoutEntry, setKeyForNewHandoutEntry] = useState(0);
    
    const startEditingCount = () => { !props.readOnly && !isSubarticle && !isInplaceEditAlreadyShowing && setIsEditingCount(true); }
    const stopEditingCount = () => { setIsEditingCount(false); }

    const checkIfCountIsValid = (enteredCount) => {
        const countToCheck = parseInt(enteredCount);
        if(countToCheck <= 0) {
            return false;
        }

        // the reserverd count cannot be smaller then the already handed out count
        const handedoutCount = getHandedoutCount(handouts);
        if(countToCheck < handedoutCount) {
            return false;
        }

        return true;
    }

    // change as typing of rented count
    const changeCurrentCount = (event) => {
        let newCount = event.target.value;
        newCount = newCount.replaceAll(",", "");
        newCount = newCount.replaceAll(".", "");

        if(!isNaN(newCount)) {
            setIsCountValid(true);
            setCurrentCount(newCount);
        }
    }

    // the user cancels editing the current count
    const onCancelCurrentCount = () => {
        // resets the count
        setCurrentCount(props.count);
        setIsCountValid(true);
        stopEditingCount();
    }

    // the user confirms editing he current rented count
    const onOkCurrentCount = async () => {
        const isNewCountValid = checkIfCountIsValid(currentCount);

        setIsCountValid(isNewCountValid);

        if(isNewCountValid) {
            // this has do to be called first, otherwise InplaceEditPanel is active remains on true, which will set isInplaceEditAlreadyShowing on showing 
            stopEditingCount();

            const wasCountChanged = await props.onDoCountChange(currentCount);
            if(wasCountChanged) {
                // stopEditingCount();
            }
        }
    }    

    const checkCanChangeHandoutCount = (handout, newCount) => {
        // get the already handed out count without the entry that was just changed
        // if handout is empty, then this is the sum of all handed out articles, for the case that we 
        const otherHandoutsCount = getHandedoutCount(handouts, handout);

        // the sum of the other handouts and the new count (were we to change it) should still be at least the count of already returned count
        return otherHandoutsCount + newCount >= props.returned_count;
    }

    const onAddNewHandout = (count, day) => {
        dispatch(addNewHandout({article_id: props.article_id, subarticle_id: props.subarticle_id, count, day: convertDateForServer(day),
            recurring_id: props.recurring_id, recurrence_pos: props.recurrence_pos}));
    }

    const onHandoutChanged = (handoutId, count, day) => {
        dispatch(changeHandout({handout_id: handoutId, article_id: props.article_id, subarticle_id: props.subarticle_id, count, day: convertDateForServer(day),
            recurring_id: props.recurring_id, recurrence_pos: props.recurrence_pos}));
    }

    const renderArticleHandout = (handout) => {
        // one entry of handout can allow a max count to be handed out of article's count minus the sum of the already handedout articles (not including this entry)
        const maxAllowedHandedoutCount = getMaxAllowedHandedoutCount(handouts, currentCount, handout);

        return  <CountAndDateSection className="handout_section" childredClassNamePrefix="out" key={handout.id} maxCount={maxAllowedHandedoutCount} count={handout.quantity} date={handout.date} 
            readOnly={!props.isAvailable || props.readOnly} canChangeCount={(newCount) => checkCanChangeHandoutCount(handout, newCount)}
            onCountAndDateChanged={(count, day) => onHandoutChanged(handout.id, count, day)} />
    }

    // this is the count that is allow for a new handout: article's count minus the sum of the already handedout articles
    const maxAllowedCountForNextHandedout = getMaxAllowedHandedoutCount(handouts, currentCount);

    let cssClasses = classNames("read-only count_handout_section", {all_out: maxAllowedCountForNextHandedout == 0});
    let countCssClasses = classNames("read-only", {red: !isCountValid, is_subarticle: isSubarticle});

    // make the input read only, if is a subarticle, of if another inplace editor is showing (but not for this component)
    let countInputIsReadOnly = props.readOnly || isSubarticle || (!isEditingCount && isInplaceEditAlreadyShowing);

    // the InplaceEditPanel has a z-index of 3
    // so when it becomes active, the other elements, that show on top of the InplaceEditPanel have a z-index of 4
    return (
        <div className={cssClasses}>
            <div className="count">
                <InplaceEditPanel isActive={isEditingCount} onOk={onOkCurrentCount} onCancel={onCancelCurrentCount} />

                <input value={currentCount} onChange={changeCurrentCount} readOnly={countInputIsReadOnly} className={countCssClasses}
                    style={isEditingCount ? {zIndex: "4" } : {}} 
                    onClick={startEditingCount} />
            </div>

            {props.isReservation &&
            <div className="handout_sections_container">
                {handouts && handouts.map(handout =>
                    renderArticleHandout(handout))
                }

                {/* add an empty session to be able to add handouts */}
                {maxAllowedCountForNextHandedout > 0 &&
                    <CountAndDateSection className="handout_section" childredClassNamePrefix="out" maxCount={maxAllowedCountForNextHandedout} key={keyForNewHandoutEntry} count="" date="" 
                        readOnly={ !props.isAvailable || props.readOnly} onCountAndDateChanged={onAddNewHandout} />
                }
            </div>
            }
        </div>
    );
}

CountAndHandedOutSection.propTypes = {
    isReservation: PropTypes.bool,
    isSubarticle: PropTypes.bool,
    count: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    handouts: PropTypes.array,
    returned_count: PropTypes.number,

    article_id: PropTypes.string.isRequired,
    subarticle_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    recurring_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    recurrence_pos: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,

    isAvailable: PropTypes.bool,
    readOnly: PropTypes.bool,

    onDoCountChange: PropTypes.func.isRequired
}


export default CountAndHandedOutSection;