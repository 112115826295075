import React from 'react';

import DataTable from 'react-data-table-component';
import "../scss/CommonDataTable.scss";

import { reportsCommonLang, paginationRowsPerPageOptions } from '../config/ReportsCommonConfig'

const CommonDataTable = (props) => {
    const dataTableLang = reportsCommonLang.data_table;
    const paginationLang = reportsCommonLang.data_table.pagination;

    const paginationComponentOptions = {
        rowsPerPageText: paginationLang.rowsPerPageText,
        rangeSeparatorText: paginationLang.rangeSeparatorText,
        selectAllRowsItem: false,
        selectAllRowsItemText: paginationLang.selectAllRowsItemText,
        noRowsPerPage: false
    };

    const noDataComponent = () => {
        return (
            <div style={{ padding: '24px' }}>{dataTableLang.no_data_to_show}</div>
        )
    }

	return (
		<DataTable
            className="common_data_table"
			title={props.title}
			columns={props.columns}
			data={props.data}
			progressPending={props.isLoading}
			pagination
			paginationServer
			paginationTotalRows={props.paginationTotalRows}
			onChangeRowsPerPage={props.onChangeRowsPerPage}
			onChangePage={props.onChangePage}
            paginationComponentOptions={paginationComponentOptions}
            paginationPerPage={props.paginationPerPage}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            noDataComponent={noDataComponent()}
            defaultSortAsc={props.defaultSortAsc}
            onSort={props.onSort}
            sortServer
		/>
	);
}

export default CommonDataTable;