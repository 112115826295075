import { client } from '../../common/api/client';

const baseApiUrl = "/includes/reservation/api/index.php?method=";

function getApiUrl(action) {
    return baseApiUrl + action;
}

class ReservationApi {

    static newContractFor(customer_id, contract_type) {
        return client.post(getApiUrl("newContractFor"), {customer_id, contract_type});
    }

    static getData(reservation_id) {
        return client.post(getApiUrl("getData"), {reservation_id});
    }

    static addNewArticle(reservation_id, article_id, from, to, subarticles_already_in_contract, available_subarticles) {
        return client.post(getApiUrl("addNewArticle"), {reservation_id, article_id, from, to, subarticles_already_in_contract, available_subarticles});
    }

    static setNewCustomer(reservation_id, customer_id) {
        return client.post(getApiUrl("setNewCustomer"), {reservation_id, customer_id});
    }

    static setArticleDates(reservation_id, article_id, subarticle_id, from, to, recurring_id, recurring_pos) {
        return client.post(getApiUrl("setArticleDates"), {reservation_id, article_id, subarticle_id, from, to, recurring_id, recurring_pos});
    }

    static setArticleCount(reservation_id, article_id, count, recurring_id, recurring_pos) {
        return client.post(getApiUrl("setArticleCount"), {reservation_id, article_id, count, recurring_id, recurring_pos});
    }

    static removeArticleFromContract(reservation_id, article_id, subarticle_id, recurring_id, recurring_pos) {
        return client.post(getApiUrl("removeArticleFromContract"), {reservation_id, article_id, subarticle_id, recurring_id, recurring_pos});
    }

    static anonymize(reservation_id) {
        return client.post(getApiUrl("anonymize"), {reservation_id});
    }

    static convertRequestToReservation(request_id) {
        return client.post(getApiUrl("convertRequestToReservation"), {request_id});
    }

    static rejectRequest(reservation_id, status) {
        return client.post(getApiUrl("rejectRequest"), {reservation_id, status});
    }

    static changeReservationStatus(reservation_id, status) {
        return client.post(getApiUrl("changeReservationStatus"), {reservation_id, status});
    }

    static addNewHandout(reservation_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos) {
        return client.post(getApiUrl("addNewHandout"), {reservation_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos});
    }

    static changeHandout(reservation_id, handout_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos) {
        return client.post(getApiUrl("changeHandout"), {reservation_id, handout_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos});
    }

    static addNewReturn(reservation_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos) {
        return client.post(getApiUrl("addNewReturn"), {reservation_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos});
    }

    static changeReturn(reservation_id, return_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos) {
        return client.post(getApiUrl("changeReturn"), {reservation_id, return_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos});
    }

    static handoutAll(reservation_id, day) {
        return client.post(getApiUrl("handoutAll"), {reservation_id, day});
    }

    static returnAll(reservation_id, day) {
        return client.post(getApiUrl("returnAll"), {reservation_id, day});
    }

    static getScanArticlesQRCode(reservation_id, operation) {
        return client.get("/includes/reservation/api/ScanArticlesQRCode.php?method=generate&id=" + encodeURIComponent(reservation_id) +
            "&operation=" + encodeURIComponent(operation));
    }

    static getCategories(customerTenantId) {
        return client.post(getApiUrl("getCategories"), {customerTenantId});
    }

    static getSpeziForArticles(customerTenantId, articleTenantId, start_date, start_time, end_date, end_time, filter, specFilter, locationId, categoryId) {
        return client.post(getApiUrl("getSpeziForArticles"), {customerTenantId, articleTenantId, start_date, start_time, end_date, end_time, filter, specFilter, locationId, categoryId});
    }

    static getLocationsForCustomer(customer) {
        return client.post(getApiUrl("getLocationsForCustomer"), {
            customer_id: customer.customer_address_id,
            customerTenantId: customer.tenant_id});
    }
    
    static getAlternativeSubarticles(reservation_id, article_id, subarticle_id, from, to, subarticles_already_in_contract, locationIds) {
        return client.post(getApiUrl("getAlternativeSubarticles"), {reservation_id, article_id, subarticle_id, from, to, subarticles_already_in_contract, locationIds});
    }

    static replaceSubarticleInContract(reservation_id, article_id, remove_subarticle_id, recurring_id, recurring_pos,
        new_subarticle_id, from, to) {

        return client.post(getApiUrl("replaceSubarticleInContract"), {reservation_id, article_id, remove_subarticle_id, recurring_id, recurring_pos, 
            new_subarticle_id, from, to});
    }
}

export default ReservationApi;
