
export const getRandomArbitrary = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
}

export const formatString = (s, ...params) => {
    return s.replace(/{(\d+)}/g, function(match, number) { 
        return typeof params[number] != 'undefined' ? params[number] : match;
    });
}

