/* global datePicker, ENABLE_HOURS, $ */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FromToInterval extends Component {

    constructor() {
        super();

        this.lazyChangeCallback = _.debounce(this.onDateChanged, 100);
    }

    getDateId(type, inputElem = false) {
        // gets the id for the input elem, that changes as the date changes
        if(inputElem) {
            return `article_${type}_${this.props.article_id}_${this.props.subarticle_id}_${this.props.recurring_id}_${this.props.recurrence_pos}`;
        } else {
            return `article_${type}_${this.props.article_id}_${this.props.subarticle_id}_${this.props.recurring_id}_${this.props.recurrence_pos}_wrap`;
        }
        
    }
    
    initFromDate() {
        const from = this.props.from.split(" ");
        const fromId = this.getDateId("from");
        
        datePicker.add(fromId, from[0], from[1], ENABLE_HOURS ? "noTime-remove" : "noTime", false, this.props.readOnly);
        $(`#${this.getDateId("from", true)}`).off('change').on('change', this.lazyChangeCallback);
    }

    initToDate() {
        const to = this.props.to.split(" ");
        const toId = this.getDateId("to");

        datePicker.add(toId, to[0], to[1], ENABLE_HOURS ? "noTime-remove" : "noTime", false, this.props.readOnly);
        $(`#${this.getDateId("to", true)}`).off('change').on('change', this.lazyChangeCallback);
    }

    // same class names are used in ArticlesTableHeader
    componentDidMount() {
        this.initFromDate();
        this.initToDate();
    }

    componentWillUnmount() {
        console.log("componentWillUnmount in FromToInterval");
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.readOnly !== this.props.readOnly) {
            // Recreate the from-to time pickers to set disabled attribute
            this.initFromDate();
            this.initToDate();
        }
    }

    getPickerValue(pickerType) {
        const pickerId = "#" + this.getDateId(pickerType, true);

        let pickerVal, 
            time = "00:00";

        const $pickerOutput = $(pickerId).siblings(".datetimepicker-output");
        if($pickerOutput.length > 0 && (pickerVal = $pickerOutput.val()) !== '') {
            time = pickerVal.slice(-5);
        }	

        return {
            [pickerType + "DayTimestamp"]: $(pickerId).pickadate('picker').get('select').pick,
            [pickerType + "Time"]: time
        };
    }

    onDateChanged = () => {
        const {fromDayTimestamp, fromTime} = this.getPickerValue("from");
        const fromDateTime = moment(fromDayTimestamp);

        const {toDayTimestamp, toTime} = this.getPickerValue("to");
        const toDateTime = moment(toDayTimestamp);

        this.props.onChange(fromDateTime.format('YYYY-MM-DD') + "T" + fromTime, toDateTime.format('YYYY-MM-DD') + "T" + toTime);
    }

    render() {
        return (
            <>
                <div className="from datepicker_root start_date_picker">
                    <div id={this.getDateId("from")}  className="datepicker-wrap"></div>
                </div>
                <div className="to datepicker_root">
                    <div id={this.getDateId("to")} className="datepicker-wrap"></div>
                </div>
            </>
        );
    }
}

FromToInterval.propTypes = {
    article_id: PropTypes.string.isRequired,
    subarticle_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    recurring_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    recurrence_pos: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,

    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,

    onChange: PropTypes.func.isRequired
}

export default FromToInterval;