import React from "react";
import PropTypes from 'prop-types';
import Select from 'react-select'
import "../scss/DropdownSelect.scss";
import { capitalize } from "lodash";

const DropdownSelect = (props) => {
    const style = {
        option: (styles, state) => ({
            ...styles, 
            color: state.isSelected ? "#FFF" : styles.color,
            backgroundColor: state.isSelected ? "#6DC1B3" : styles.color,
            "&:hover": {
              color: state.isSelected ? "#FFF" : "#000",
              backgroundColor: state.isSelected ? "#6DC1B3" : "#F9F9F9"
            }
        }),
        control: (styles, state) => ({
            ...styles,
            boxShadow: state.isFocused ? "0 0 0 0.1rem #CED4DA" : 0,
            borderColor: state.isFocused ? "#CED4DA" : "#CED4DA",
            "&:hover": {
              borderColor: state.isFocused ? "#CED4DA" : "#CED4DA"
            }
        }),
        groupHeading: styles => ({
            ...styles,
            textTransform: capitalize
        }),
    };

    return (
        <Select styles={style} className='react-select-container' classNamePrefix="react-select" isSearchable={false} isOptionDisabled={(option) => false} {...props} />
    );
}

DropdownSelect.propTypes = {
    className: PropTypes.string,

    options: PropTypes.array.isRequired,

    onChange: PropTypes.func.isRequired
}

export default DropdownSelect;