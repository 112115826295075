import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import DOMPurify from 'dompurify';

import ActionButton from '../../../common/components/ActionButton';
import Popup from '../../../common/components/Popup';

import lang from "../../../common/lang/de.json";

import ReservationApi from "../../api/ReservationApi";

import { getSubarticleFullNumber } from './ArticlesUtils';

import {
    selectReservationId,
    convertDateForServer,
    selectReservationCustomerLocations
} from '../../store/reservationSlice';

const SubarticlesPopup = (props) => {
    const reservationId = useSelector(selectReservationId);
    const reservationCustomerLocations = useSelector(selectReservationCustomerLocations);

    const [loadingAvailableAlternativeSubarticles, setLoadingAvailableAlternativeSubarticles] = useState(false);
    const [availableAlternativeSubarticles, setAvailableAlternativeSubarticles] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    const { title, article, subarticle, onActionConfirmed, onCancel, showOkButton, lastUsedFromInAvailabilityCheck, lastUsedToInAvailabilityCheck } = props;

    useEffect(() => {
        getAlternativeSubarticles();
    }, []);
    
    const getRequestDates = () => {
        // the props last... have the value of last availability check done for this article
        // they are used if the user changes the from/to dates and then wants to replace the subarticle: these last values are used rather the the initial ones
        const requestFromDate = lastUsedFromInAvailabilityCheck != '' ? lastUsedFromInAvailabilityCheck : convertDateForServer(subarticle.start_date);
        const requestToDate = lastUsedToInAvailabilityCheck != '' ? lastUsedToInAvailabilityCheck : convertDateForServer(subarticle.end_date);

        return { requestFromDate, requestToDate };
    }
    
    const getAlternativeSubarticles = async () => {
        setLoadingAvailableAlternativeSubarticles(true);

        const subarticles_already_in_contract = article.subarticles.map(sa => sa.subarticle_id)

        const { requestFromDate, requestToDate } = getRequestDates();
        
        const response = await ReservationApi.getAlternativeSubarticles(reservationId, article.a_id, subarticle.subarticle_id, 
            requestFromDate, requestToDate, subarticles_already_in_contract, reservationCustomerLocations.map(l => l.id) );

        if(response["alternative_subarticles"]) {
            prepareAlternativeSubarticles(response["alternative_subarticles"], requestFromDate, requestToDate);
        } else {
            setLoadingAvailableAlternativeSubarticles(false);
        }
    }

    const prepareAlternativeSubarticles = (responseAlternativeSubarticle, requestFromDate, requestToDate) => {
        const alternativeSubarticles = [];

        responseAlternativeSubarticle.map((alternativeSubarticle) => {
            const keysOfAlternativeSubarticle = Object.keys(alternativeSubarticle);

            // each elemnt of the returned subarticles has the format {subarticleId: {prefix: XXXX, number: YY, locationName: ASD, spezi: [attr: sasa, spezi: sddds]}}
            if(keysOfAlternativeSubarticle.length == 1) {
                const subarticleId = keysOfAlternativeSubarticle[0];

                const subarticlePrefix = alternativeSubarticle[subarticleId]['prefix'];
                const subarticleNumber = alternativeSubarticle[subarticleId]['number'];
                const subarticleLocation = alternativeSubarticle[subarticleId]['locationName'];
                const subarticleSpezi = alternativeSubarticle[subarticleId]['spezi'];

                alternativeSubarticles.push({
                    subarticleId,
                    subarticlePrefix,
                    subarticleNumber,
                    subarticleLocation,
                    subarticleSpezi
                })
            }
        })
        
        setLoadingAvailableAlternativeSubarticles(false);

        // already sorted by least used
        setAvailableAlternativeSubarticles(alternativeSubarticles);

        setFromDate(requestFromDate);
        setToDate(requestToDate);
    }

    const renderSpeziTooltip = (spezis) => {
        let i = 1;
        let speziToolTip = [];
        
        for(var spezi of spezis) {
            speziToolTip.push(
                <div key={'spezi_'+i} >
                    <div className='sub-title' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(spezi.attr) }} />
                    <div className='data' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(spezi.spezi) }} />
                </div>
            );

            i++;
        }

        return speziToolTip;
    };

    const renderAlternativeSubarticle = (alternativeSubarticle) => {
        const {subarticleId, subarticlePrefix, subarticleNumber, subarticleLocation, subarticleSpezi} = alternativeSubarticle;
        const subarticleHasSpecifications = subarticleSpezi.length > 0;
        let articleNumber = getSubarticleFullNumber(article.gnr, subarticlePrefix, subarticleNumber);

        return (
            <div key={subarticleId} className="alternative_article_number">
                <span className="subarticle_number">
                    <span>{articleNumber}</span>
                    { subarticleHasSpecifications &&
                        <svg data-tip='' data-for={"popup_spezi_tooltip_" +articleNumber} className="svg_icon">
                            <use xlinkHref={"#icon-info"}></use>
                        </svg>
                    }
                </span>
                <span>{subarticleLocation}</span>

                <ActionButton onClick={() => onActionConfirmed(subarticleId, fromDate, toDate)} text={lang.select} />
                
                { subarticleHasSpecifications &&
                    <ReactTooltip id={"popup_spezi_tooltip_" +articleNumber} className="react-tooltip"  place="bottom" offset={{left: -55}} type="light" arrowColor="transparent">
                        {renderSpeziTooltip(subarticleSpezi)}
                    </ReactTooltip>
                }
            </div>
        );
    }

    return (
        <Popup title={title} showOkButton={showOkButton} className="alternative_subarticles_popup"
            onOkClicked={onActionConfirmed} onCancelClicked={() => onCancel()}>

            <div className="alternative_subarticles">
                { /* Do not show table header for now */
                false && 
                <div key="header" className="alternative_article_number alternative_article_number_header text-uppercase">
                    <span className="subarticle_number">
                        {lang.reservation.replace_add_subarticle_popup.subarticle_number}
                    </span>
                    <span>{lang.reservation.replace_add_subarticle_popup.subarticle_location}</span>
                </div> /* Do not show table header for now */ }

                { loadingAvailableAlternativeSubarticles && 
                    <div className="alternative_article_number">
                        {lang.reservation.replace_add_subarticle_popup.loading_subarticles}
                    </div>
                }

                {!loadingAvailableAlternativeSubarticles && availableAlternativeSubarticles.map((alternativeSubarticle) =>
                    renderAlternativeSubarticle(alternativeSubarticle))
                }

                {!loadingAvailableAlternativeSubarticles && availableAlternativeSubarticles.length === 0 &&
                    <div className="alternative_article_number">
                        {lang.reservation.replace_add_subarticle_popup.no_subarticles_available}
                    </div>
                }
            </div>

        </Popup>
    );
}

SubarticlesPopup.propTypes = {
    title: PropTypes.string.isRequired,
    article: PropTypes.object.isRequired,
    subarticle: PropTypes.object.isRequired,
    lastUsedFromInAvailabilityCheck: PropTypes.string.isRequired,
    lastUsedToInAvailabilityCheck: PropTypes.string.isRequired,
    onActionConfirmed: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    showOkButton: PropTypes.bool.isRequired
}

export default SubarticlesPopup;