/* global qvapp */
import lang from "../../../common/lang/de.json";

export const reportsCommonLang = lang.reports.common;

export const paginationRowsPerPageOptions = [10, 20, 30, 50];

export const commonColumns = {
    "contract_number": {
        name: reportsCommonLang.data_table.table_columns_names.contract_number,
        selector: row => row.vertragsnr,
        width: "130px",
        minWidth: "130px",
        sortable: true,
        sortField: 'vertragsnr',
    },
    "article_number": {
        name: reportsCommonLang.data_table.table_columns_names.article_number,
        selector: row => row.gnr,
        width: "80px",
        minWidth: "80px",
        sortable: true,
        sortField: 'gnr',
    },
    "article_name": {
        name: reportsCommonLang.data_table.table_columns_names.article_name,
        selector: row => row.name,
        minWidth: "200px",
        grow: 2,
        sortable: true,
        sortField: 'name',
    },
    "quantity": {
        name: reportsCommonLang.data_table.table_columns_names.quantity,
        selector: row => row.a_menge,
        width: "50px",
        minWidth: "50px"
    },
    "customer_name": {
        name: qvapp.i18n.t("label_lender"),
        selector: row => row.customer_name,
        minWidth: "200px",
        grow: 1,
        sortable: true,
        sortField: 'customer_name',
    },
    "start_date": {
        name: reportsCommonLang.data_table.table_columns_names.start,
        selector: row => row.start,
        width: "130px",
        minWidth: "130px",
        sortable: true,
        sortField: 'a_von',
    },
    "end_date": {
        name: reportsCommonLang.data_table.table_columns_names.end,
        selector: row => row.end,
        width: "130px",
        minWidth: "130px",
        sortable: true,
        sortField: 'a_bis',
    },
    "contract_status": {
        name: reportsCommonLang.data_table.table_columns_names.status,
        selector: row => row.status,
        width: "170px",
        minWidth: "170px"
    }
};